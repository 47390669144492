<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div v-show="lista_permissoes_filial.ra_Atendimento" class="col-xxl-4 mt-5">
        <a
          @click="trocar_pagina('atendimento')"
          class="card card-custom wave wave-animate-fast wave-primary"
        >
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('atendimento')"
                  class="
                    text-dark text-hover-danger
                    font-weight-bold font-size-h4
                    mb-3
                  "
                >
                {{$t("ATENDIMENTO.NOME")}}
                </a>
                <div class="text-dark-75">  {{$t('ATENDIMENTO.DASHBOARD')}}</div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div v-show="lista_permissoes_filial.ra_Usuario" class="col-xxl-4 mt-5">
        <a
          @click="trocar_pagina('gerenPaciente')"
          class="card card-custom wave wave-animate-fast wave-info"
        >
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('gerenPaciente')"
                  class="
                    text-dark text-hover-danger
                    font-weight-bold font-size-h4
                    mb-3
                  "
                >
                  Paciente
                </a>
                <div class="text-dark-75"> {{ $t('PACIENTES.DASHBOARD') }}</div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div v-show="lista_permissoes_filial.ra_Usuario" class="col-xxl-4 mt-5">
        <a
          @click="trocar_pagina('gerenFuncionarios')"
          class="card card-custom wave wave-animate-fast wave-success"
        >
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('gerenFuncionarios')"
                  class="
                    text-dark text-hover-danger
                    font-weight-bold font-size-h4
                    mb-3
                  "
                >
                  Funcionarios
                </a>
                <div class="text-dark-75">
                  {{ $t('FUNCIONARIO.DASHBOARD') }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div v-show="lista_permissoes_filial.ra_Produto" class="col-xxl-4 mt-5">
        <a
          @click="trocar_pagina('estoquesAtivos')"
          class="card card-custom wave wave-animate-fast wave-dark"
        >
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('produto')"
                  class="
                    text-dark text-hover-danger
                    font-weight-bold font-size-h4
                    mb-3
                  "
                >
                  Estoque
                </a>
                <div class="text-dark-75">{{ $t('ESTOQUES.DASHBOARD') }}</div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <!-- <div v-show="lista_permissoes_filial.ra_Usuario" class="col-xxl-4 mt-5">
        <a
          @click="trocar_pagina('atendimentos')"
          class="card card-custom wave wave-animate-fast wave-primary"
        >
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('gerenFuncionarios')"
                  class="
                    text-dark text-hover-danger
                    font-weight-bold font-size-h4
                    mb-3
                  "
                >
                  Examens
                </a>
                <div class="text-dark-75">Aqui puedes visualizar examenes</div>
              </div>
            </div>
          </div>
        </a>
      </div> -->
      <div v-show="lista_permissoes_filial.ra_Atendimento" class="col-xxl-4 mt-5">
        <a
        @click="trocar_pagina('atendimento')"
          class="card card-custom wave wave-animate-fast wave-danger"
        >
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column text-center">
                <a
                @click="trocar_pagina('atendimento')"
                  class="
                    text-dark text-hover-danger
                    font-weight-bold font-size-h1
                    mb-3
                  "
                >
                  {{ cont }}
                </a>
                <div class="text-dark-75">Atendimentos realizados no total</div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div v-show="lista_permissoes_filial.ra_Atendimento" class="col-xxl-4 mt-5">
        <a
        @click="trocar_pagina('atendimento')"
          class="card card-custom wave wave-animate-fast wave-warning"
        >
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column text-center">
                <a
                @click="trocar_pagina('atendimento')"
                  class="
                    text-dark text-hover-danger
                    font-weight-bold font-size-h1
                    mb-3
                  "
                >
                  {{ num_atendimentos_dia.total }}
                </a>
                <div class="text-dark-75">Atendimentos realizados HOJE</div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div v-show="lista_permissoes_filial.ra_Usuario" class="col-xxl-12 mt-5">
        <a
          
          class="card card-custom wave-animate-fast wave-primary"
        >
          <div class="card-body">
            <Atendimentos></Atendimentos>
          </div>
        </a>
      </div>
      <div v-show="lista_permissoes_filial.ra_Usuario" class="col-xxl-12 mt-5">
        <a
         
          class="card card-custom wave-animate-fast wave-primary"
        >
          <div class="card-body">
            <Saidas></Saidas>
          </div>
        </a>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import Saidas from "@/view/components/relatorios/graficos/saidas_filial_por_classe";
import Atendimentos from "@/view/components/relatorios/graficos/atendimentos_filial";
import { setTimeout } from "timers";
export default {
  data() {
    return {
      cont: 0,
    };
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    num_atendimentos_filial() {
      return this.$store.state.relatorios.num_atendimentos_filial;
    },
    num_atendimentos_dia() {
      return this.$store.state.relatorios.num_atendimentos_dia;
    },
  },
  name: "dashboard",
  components: { Saidas, Atendimentos },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },

  created() {
    this.atendimentos();
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    async atendimentos() {
      await this.$store.dispatch("relatorios/get_num_atentimentos");
      await this.$store.dispatch("relatorios/get_num_atentimentos_dia");
      while (this.cont < this.num_atendimentos_filial.total) {
       
          this.cont +=1
        
      }
    },

    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    trocar_pagina(rota) {
      this.$router.push({ name: rota });
    },
  
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
