<template>
  <div class="justify-content-center">
    <div class="col-md-3 pull-rigth">
      <b-dropdown variant="light">
        <template v-slot:button-content>
          <span class="">
            <i class="mr-2 fas fa-calendar"></i> {{ ano }}
          </span>
        </template>
        <b-dropdown-item v-for="(item, index) in lista_anos" :key="index" @click="selectAno(item)">
          • {{ item.label }}
        </b-dropdown-item>
      </b-dropdown>
    </div>


    <div class="col-md-12">
      <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
        :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
        :height="height" />
    </div>

  </div>
</template>
  
<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => { },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [40, 39, 10, 40, 39, 80, 40],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      lista_anos: [
        {
          'id': 1,
          "label": 2022,
        },
        {
          'id': 2,
          "label": 2023,
        },
        {
          'id': 3,
          "label": 2024,
        },
        {
          'id': 4,
          "label": 2025,
        }
      ],
      ano: "2024",
      data_inicio: "2024-01-01",
      data_fim: "2024-12-31",
    };
  },
  created() {
    this.listar_atendimentos_filial();
  },
  computed: {
    num_atendimentos_filial_mes() {
      return this.$store.state.relatorios.num_atendimentos_filial_mes;
    },
  },
  methods: {
    async selectAno(value) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      this.ano = value.label
      this.data_inicio = value.label + '-01-01'
      this.data_fim = value.label + '-12-31'
      await this.listar_atendimentos_filial()
      this.$store.dispatch("configEmpresa/MudarPreloader", false);


    },
    async listar_atendimentos_filial() {
      const filtro = {
        data_fim: this.data_fim,
        data_inicio: this.data_inicio
      }
      await this.$store.dispatch("relatorios/num_atendimentos_filial_mes", filtro);
      console.log(this.num_atendimentos_filial_mes);
      var mes = [];
      var valores = [];
      for (let i = 0; i < this.num_atendimentos_filial_mes.length; i++) {
        const m = this.num_atendimentos_filial_mes[i].mes;
        if (m == 1) m = "Enero";
        if (m == 2) m = "Febrero"
        if (m == 3) m = "Marzo"
        if (m == 4) m = "Abril"
        if (m == 5) m = "Mayo"
        if (m == 6) m = "Junio"
        if (m == 7) m = "Julio"
        if (m == 8) m = "Agosto"
        if (m == 9) m = "Septiembre"
        if (m == 10) m = "Octubre"
        if (m == 11) m = "Noviembre"
        if (m == 12) m = "Diciembre"
        mes.push(m);
        valores.push(this.num_atendimentos_filial_mes[i].total)


      }
      var newData = {
        labels: mes,
        datasets: [
          {
            label: "Atendimientos",
            backgroundColor: "#f87979",
            data: valores,
          },
        ],
      }

      this.chartData = newData
    },
  },
};
</script>
  